import React, { useState } from "react";
import Layout from "../components/layout";
import ListPart from "../components/list-part";
import MangaDesc from "../components/manga-decs";
import Seo from "../components/seo";
import { graphql } from "gatsby";

export default function List({ data }) {
  const initCount = 12;
  const [count, setCount] = useState(initCount);
  const [nameS, setNameS] = useState("");

  const nameSearch = (event) => {
    setNameS(event.target.value);
    setCount(initCount);
  };
  const moreButton = () => {
    setCount(count + 6);
  };

  let mangas = data.mangaData.data;
  mangas.sort((mA, mB) => {
    return mB.free_episode_count - mA.free_episode_count;
  });

  if (nameS.length > 0) {
    mangas = mangas.filter(function (manga) {
      return manga.name.indexOf(nameS) !== -1;
    });
  }
  const baseCount = mangas.length;
  if (baseCount >= count) {
    mangas = mangas.slice(0, count);
  }

  return (
    <Layout>
      <Seo
        title={"一覧"}
        description={
          "期間限定の無料公開など、無料掲載話数の多い漫画から順に表示しております。"
        }
      />
      <h1>一覧</h1>
      <section>
        <p>
          期間限定の無料公開など、無料掲載話数の多い漫画から順に表示しております。
        </p>
        <div className="buttonDiv">
          <input
            type={"text"}
            className="full-text-form"
            placeholder="タイトル検索"
            onChange={nameSearch}
          />
        </div>
      </section>
      <section>
        <div className="osusumeParent">
          {mangas.map((manga) => (
            <ListPart
              key={manga.slug}
              to={`/manga/${manga.slug}`}
              isNewTab={false}
              imgSrc={manga.imageUrl}
              name={manga.name}
              desc={<MangaDesc manga={manga} />}
              up={manga.latest}
            />
          ))}
        </div>
        {baseCount > count ? (
          <div className="buttonDiv">
            <button className="btn as-normal" onClick={moreButton}>
              もっと見る
            </button>
          </div>
        ) : (
          ""
        )}
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    mangaData(data: {}) {
      id
      data {
        slug
        name
        author
        imageUrl
        description
        latest
        free_episode_count
        epiList {
          episodes {
            date
            title
            thumbnail
            link
          }
          mediaId
        }
      }
    }
  }
`;
