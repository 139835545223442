// src/components/manga-decs.jsx
import React from "react";

export default function MangaDesc({ manga }) {
  const mHash = {
    1: {
      src: "https://cdn-ak.shonenjumpplus.com/images/favicon.ico",
      alt: "ジャンプ",
    },
    2: {
      src: "https://cdn.pocket.shonenmagazine.com/images/favicon.ico",
      alt: "マガポケ",
    },
    3: {
      src: "https://comic-days.com/_next/static/images/favicon.4385e7f9.ico",
      alt: "コミックDAYS",
    },
  };
  let logos = manga.epiList
    .filter((epi) => epi.mediaId > 0)
    .map((epi) => (
      <img
        key={"img" + epi.mediaId}
        src={mHash[epi.mediaId]["src"]}
        alt={mHash[epi.mediaId]["alt"]}
        width="32px"
        height="32px"
      />
    ));
  return (
    <div key={manga.slug}>
      {logos}　{manga.free_episode_count}話無料
    </div>
  );
}
